import {
  DesktopHeader,
  Drawer,
  Header,
  layoutClasses,
  LayoutRoot,
  ModuleSection,
  ProfileMenuItem,
  ProfileSection,
  Spinner,
} from '@ifca-ui/core'
import {
  Home as HomeIcon,
  Logout as LogoutIcon,
  Profile as ProfileIcon,
  AccessSecurity as AccessSecurityIcon,
} from '@ifca-ui/icons/dist/index.esm'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SideMenuBg from 'assets/images/product/sidemenu-background.svg'
import clsx from 'clsx'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import * as React from 'react'
import { NavLink, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { ACCT_PATH } from 'containers/modules/Account/Routes'
import { REPORT_PATH } from '../modules/Report/Routes'
import { ACCESS_SECURITY_PATH } from '../modules/AccessSecurity/Routes'
import AccessControl from 'component/AccessControl/AccessControl'
import {
  Permission,
  useGetPermissionEnumQuery,
  useGetUserProfileLazyQuery,
} from '../../generated/graphql'
import { Suspense, useEffect, useState } from 'react'
import {
  getUserProfile,
  setUserProfile,
  updateUserProfileObservable,
  UserProfile,
} from '../../UserDetail'
import { permission, setPermission } from '../../Permission'
import { Dialog, DialogData } from '../../component/Dialog/Dialog'

interface Modules {
  name?: string
  path?: string
  icon?: any
  iconActived?: any
  ACL: number | number[] | null
}
interface MainLayoutProps {
  children?: React.ReactNode
  window?: () => Window
}
const MainLayout: React.FC<MainLayoutProps> = ({ children, window }) => {
  const navigate = useNavigate()
  const headerBgURL = require('assets/images/header/header-bg.svg').default
  const { pathname } = useLocation()
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [confirmDialogNavigation, setConfirmDialogNavigation] =
    useState<string>('')

  //#region API call
  const [
    getProfile,
    {
      data: { profile: userProfile } = {
        profile: null,
      },
    },
  ] = useGetUserProfileLazyQuery({
    onCompleted: data => {
      if (data && data?.profile) {
        setUserProfile(data.profile as UserProfile)
      }
    },
  })

  const {
    data: { getPermissionEnum: PermissionEnum } = {
      getPermissionEnum: null,
    },
    loading: getPermissionEnumLoading,
  } = useGetPermissionEnumQuery({
    onCompleted: data => {
      setPermission(data?.getPermissionEnum)
    },
  })

  //#endregion

  const ModulesList: Modules[] = [
    {
      name: 'Home',
      path: REPORT_PATH.DASHBOARD,
      icon: <HomeIcon className="svg-icon" />,
      // iconActived: <WhiteHome className="svg-icon" />,
      ACL: permission(Permission.ReportDesignerReportView),
    },
    {
      name: 'Access Security',
      path: ACCESS_SECURITY_PATH.ACCESS_SECURITY_MENU_LIST,
      icon: <AccessSecurityIcon className="svg-icon" />,
      // iconActived: <AccessSecurityIcon className="svg-icon" />,
      ACL: permission(Permission.ReportDesignerAccessSecurityView),
    },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const profileMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ProfileMenuItem
        onClick={() => {
          handleClose()
          confirmationDialogHandler(ACCT_PATH.PROFILE)
        }}
      >
        {' '}
        <ProfileIcon width="18px" height="18px" />
        Profile
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() => {
          handleClose()
          confirmationDialogHandler(AUTH_PATH.LOGOUT)
        }}
      >
        {' '}
        <LogoutIcon width="18px" height="18px" />
        Logout
      </ProfileMenuItem>
    </Menu>
  )
  const handleDrawerOpen = () =>
    rootDispatch({ type: 'isDrawerOpen', payload: !rootState.isDrawerOpen })

  useEffect(() => {
    if (isDesktop && !rootState.isDrawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
    }
  }, [rootState.isDrawerOpen, isDesktop])

  const [authOpen, setAuthOpen] = React.useState(false)
  const [acctOpen, setAcctOpen] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [componentOpen, setComponentOpen] = React.useState(true)
  const handleAcctClick = () => {
    setAcctOpen(!acctOpen)
  }
  const handleAuthClick = () => {
    setAuthOpen(!authOpen)
  }
  const handleErrorClick = () => {
    setErrorOpen(!errorOpen)
  }
  const handleComponentClick = () => {
    setComponentOpen(!componentOpen)
  }

  useEffect(() => {
    updateUserProfileObservable().subscribe(x => {
      if (x) {
        getProfile()
      }
    })
    getProfile()
  }, [])

  const confirmationDialogHandler = (route: string) => {
    if (rootState.contentIsModifiedFn && rootState.contentIsModifiedFn()) {
      setConfirmDialog(true)
      setConfirmDialogNavigation(route)
    } else {
      navigate(route)
    }
  }

  //#region Dialog
  const confirmationDialog = (
    <Dialog
      open={confirmDialog}
      onClose={() => setConfirmDialog(false)}
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      defaultDialogData={
        {
          header: {
            title: {
              leftTopTitle: {
                title: {
                  text: 'Exit Confirmation',
                },
              },
            },
          },
          bodyText:
            'Are you sure you want to exit? Your changes will not be saved.',
        } as DialogData
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setConfirmDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: e => {
              rootDispatch({
                type: 'contentIsModifiedFn',
                payload: () => {
                  return false
                },
              })
              setConfirmDialog(false)
              navigate(confirmDialogNavigation)
            },
          },
        ],
      }}
    />
  )
  //#endregion

  return (
    <>
      {!userProfile ? (
        <Spinner />
      ) : (
        <LayoutRoot
          component="div"
          className={layoutClasses.root}
          display="flex"
        >
          {isDesktop ? (
            <DesktopHeader
              title="Report Designer"
              leftIcon={{
                props: {
                  onClick: () => handleDrawerOpen(),
                },
              }}
            />
          ) : null}

          <Header
            headerBgURL={headerBgURL}
            leftIcon={rootState.headerComponent?.leftIcon}
            rightIcon={rootState.headerComponent?.rightIcon}
            topSection={rootState.headerComponent?.topSection}
            bottomSection={rootState.headerComponent?.bottomSection}
          />
          <Drawer
            container={container}
            onClose={handleDrawerOpen}
            // open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
            open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
            variant={isDesktop ? 'persistent' : 'temporary'}
          >
            <ProfileSection
              drawerImage={SideMenuBg}
              avatar={{
                src:
                  getUserProfile()?.avatar ??
                  'https://scontent.fkul8-1.fna.fbcdn.net/v/t1.6435-9/82939221_2922930267717357_668748647327858688_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=vj2htXI6WtoAX-3uAoA&_nc_ht=scontent.fkul8-1.fna&oh=42de33a80efda1ba65cfa21bc580c373&oe=617F4CC6',
              }}
              profile={{
                title: getUserProfile()?.name,
                subTitle: getUserProfile()?.email,
              }}
              dropDownIcon={{
                onClick: e => handleClick(e),
              }}
            />
            <ModuleSection as="div">
              <List disablePadding>
                {ModulesList.map((module, index) =>
                  module.ACL ? (
                    <AccessControl allowedPermissions={module.ACL}>
                      <ListItem
                        key={index}
                        dense
                        className={pathname === module.path ? 'active' : ''}
                        divider
                        onClick={() => {
                          confirmationDialogHandler(module.path)
                        }}
                      >
                        <ListItemIcon>
                          {module.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={module.name}
                          primaryTypographyProps={{
                            variant: 'inherit',
                          }}
                        />
                      </ListItem>
                    </AccessControl>
                  ) : (
                    <ListItem
                      key={index}
                      dense
                      divider
                      classes={pathname === module.path ? 'active' : ''}
                      onClick={() => {
                        confirmationDialogHandler(module.path)
                      }}
                    >
                      <ListItemIcon>
                        {module.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={module.name}
                        primaryTypographyProps={{
                          variant: 'inherit',
                        }}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </ModuleSection>
          </Drawer>
          {profileMenu}
          {confirmationDialog}
          <Box
            component="main"
            className={clsx(layoutClasses.mainContent, {
              [layoutClasses.contentShift]: rootState.isDrawerOpen,
            })}
          >
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </Box>
        </LayoutRoot>
      )}
    </>
  )
}
export default MainLayout

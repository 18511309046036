import { getUserProfile } from "UserDetail";

interface AccessControlProps {
  allowedPermissions: number | number[]
  children: React.ReactNode
}

const AccessControl = (props: AccessControlProps) => {
  const { children, allowedPermissions } = props

  const checkPermissions = (allowedPermissions: number | number[]): boolean => {
    //get from global
    const userPermission:number[] = getUserProfile()?.userPermission;
    let permit = false

    //if super user
    if (getUserProfile()?.superUser) {
      permit = true
    }else if (userPermission && userPermission.length > 0) {
      if (Array.isArray(allowedPermissions)) {
        permit = userPermission.some(x => allowedPermissions.some(y => y == x))
      } else {
        permit = userPermission.some(x => x == allowedPermissions)
      }
    }

    return permit
  }

  const permitted = checkPermissions(allowedPermissions)
  return <>{permitted ? children : null}</>
}

export default AccessControl

import crypto from 'crypto';

export class CryptoHelper {
    private static algorithm: string = "aes-256-cbc";
    //Note must be 16 Byte initVectorString
    private static initVectorString: string = "gY6gM3qO1xH0tK1w";
    //Note must be 32 Byte securitykeyString
    private static securityKeyString: string = "iV9aJ0sF8rB3mS6tX7iX7vF4rP3dR6aK";

    static encryption(value: string): string {
        //#region iv process
        var vectorBuf = new ArrayBuffer(this.initVectorString.length);
        let initVector: Uint8Array = new Uint8Array(vectorBuf);
        for (var i = 0, strLen = this.initVectorString.length; i < strLen; i++) {
            initVector[i] = this.initVectorString.charCodeAt(i);
        }
        //#endregion

        //#region security key process
        var securityKeyBuf = new ArrayBuffer(this.securityKeyString.length);
        let securityKey: Uint8Array = new Uint8Array(securityKeyBuf);
        for (var i = 0, strLen = this.securityKeyString.length; i < strLen; i++) {
            securityKey[i] = this.securityKeyString.charCodeAt(i);
        }
        //#endregion

        // the cipher function
        const cipher = crypto.createCipheriv(this.algorithm, securityKey, initVector);

        // encrypt the message
        // input encoding
        // output encoding
        let encryptedData = cipher.update(value, "utf8", "hex");

        encryptedData += cipher.final("hex");

        return encryptedData
    }


    static decryption(value: string): string {
        if(!value){
            return null;
        }
        //#region iv process
        var vectorBuf = new ArrayBuffer(CryptoHelper.initVectorString.length);
        let initVector: Uint8Array = new Uint8Array(vectorBuf);
        for (var i = 0, strLen = CryptoHelper.initVectorString.length; i < strLen; i++) {
            initVector[i] = CryptoHelper.initVectorString.charCodeAt(i);
        }
        //#endregion

        //#region security key process
        var securityKeyBuf = new ArrayBuffer(CryptoHelper.securityKeyString.length);
        let securityKey: Uint8Array = new Uint8Array(securityKeyBuf);
        for (var i = 0, strLen = CryptoHelper.securityKeyString.length; i < strLen; i++) {
            securityKey[i] = CryptoHelper.securityKeyString.charCodeAt(i);
        }
        //#endregion

        // the decipher function
        const decipher = crypto.createDecipheriv(this.algorithm, securityKey, initVector);

        let decryptedData = decipher.update(value, "hex", "utf8");

        decryptedData += decipher.final("utf8");


        return decryptedData;
    }

}



let Permission = {};

export const setPermission = (data) => {
  Permission = data
};

export const getPermission = () => {
  return Permission;
};

export const permission = (permissionName:string) => {
  return Permission[permissionName];
};
import { CryptoHelper } from './containers/helper/Crypto/Crypto';
let accessToken = "";

let test ;

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  if(!accessToken){
    accessToken = CryptoHelper.decryption(localStorage.getItem("key")) ?? "";
  }
  return accessToken;
};

export const setTest = (s) => {
  test = s;
};

export const getTest = (s) => {
  return test;
};